<template>
  <button
    class="filter-toggle show"
    data-test="location-filter-toggle"
    :class="{'filter-hide': isFilterOpen }"
    @click="toggleFilter(true)"
  >
    <span v-if="getDestinationInfoPlace">
      <span class="text-ellipsis">{{ getDestinationInfoPlace }}</span>
    </span>
    <span
      v-if="getDestinationInfoPlace && getFormatDates"
      class="divider"
    ></span>
    <span v-if="getFormatDates">
      {{ getFormatDates }}
    </span>
    <span v-if="guests" class="divider"></span>
    <span v-if="guests"> {{ guests }} guest{{ guests > 1 ? 's' : '' }} </span>
    <v-btn class="ml-2" fab dark x-small depressed color="primary">
      <v-icon dark>mdi-magnify</v-icon>
    </v-btn>
  </button>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'DestinationFiltersToggle',
  computed: {
    ...mapState('filter', ['guests']),
    ...mapState('destination', ['isFilterOpen']),
    ...mapGetters('destination', ['getDestinationInfoPlace']),
    ...mapGetters('filter', ['getFormatDates'])
  },
  methods: {
    ...mapActions('destination', {
      toggleFilter: 'toggleFilter',
    }),
  }
};
</script>
<style lang="scss"></style>
